export const realty_registration = {
    common: {
        statuses: {
            created: 'Новая',
            in_progress: 'В работе',
            waiting_user_action: 'Ожидает подписи',
            error: 'Ошибка',
            in_real_estate_register: 'В Росреестре',
            cancelled: 'Отменена',
            registered: 'Зарегистрирована',
        },
    },
    validations: {
        invalidCode: 'Введённый код неверен. Пожалуйста, попробуйте снова.',
    },
    deal_list: {
        title: 'Заявки на регистрацию',
        changed_date: 'Изменена',
        application_date: 'Дата подачи',
        planned_date: 'Плановая дата',
        dvizh: 'Заявка',
        tooltips: {
            project: 'Перейти в проект',
            changed_date: 'Последний раз, когда обновились данные по заявке',
            application_date: 'Дата подачи',
            planned_date: 'Плановой датой считается дата, когда документы рассмотрят, и статус заявки будет изменен',
        }
    },
    application: {
        title: 'Заявка на регистрацию',
    },
};