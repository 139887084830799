import { ru } from 'vuetify/locale';
import { settlement } from '~/plugins/i18n/ru/settlement.ru';
import { forms } from '~/plugins/i18n/ru/forms.ru';
import { coupon } from '~/plugins/i18n/ru/coupon.ru';
import { users } from '~/plugins/i18n/ru/users.ru';
import { booking } from '~/plugins/i18n/ru/booking.ru';
import { mortgages } from '~/plugins/i18n/ru/mortgages.ru';
import { realty_registration } from '~/plugins/i18n/ru/realtyRegistration.ru';

export const russian =  {
    $vuetify: { ...ru },
    main: {
        hello: 'Добро пожаловать!!!',
        logout: 'Выход'
    },
    login: {
        title: 'Войди в личный кабинет',
        subtitle: 'и открой все возможности',
        loginTitle: 'Логин',
        phoneTitle: 'Номер телефона',
        passwordTitle: 'Пароль',
        codeTitle: 'Код',
        btnSendCode: 'Отправить код',
        repeatSending: 'Отправить повторно',
        otherNumber: 'Другой номер',
        btnAuth: 'Авторизоваться',
        titleSendCode: 'Введите СМС-код отправленный',
        subtitleSendCode: 'на номер ',
        validations: {
            requiredPhoneNumber: 'Необходимо заполнить номер телефона',
            requiredSmsCode: 'Необходимо заполнить код из СМС',
            requiredLogin: 'Необходимо заполнить логин',
            requiredPassword: 'Необходимо заполнить пароль',
            requiredCaptcha: 'Необходимо подтвердить, что вы не бот',
            invalidCode: 'Неверный код',
            invalidLoginPassword: 'Неверный логин или пароль'
        }
    },
    error: {
        title: 'Ошибка: ',
        mainPage: 'Вернуться на главную',
        pageNotFound: 'Страница не найдена',
        accessDenied: 'Доступ запрещен',
        data: 'Не удалось получить данные',
    },
    routes: {
        menu: 'Меню',
        main:'Главная страница',
        profile:'Профиль',
        partners: 'Клуб привилегий',
        favorites: 'Избранное',
        administration: 'Администрирование',
        users: 'Пользователи',
        emailVerified: 'Email подтвержден',
        categories: 'Категории партнеров',
        partner: 'Партнер',
        settlement: 'Объекты',
        settlementMonitoring: 'Мониторинг',
        error: 'Ошибка',
        promocodes: 'Промокоды',
        certificates: 'Сертификаты',
        transactions: 'Транзакции',
        login: 'Авторизация',
        acceptances: "Записи на приемку",
        booking: "Бронирование",
        bookings: "Бронирования",
        mortgage: "Заявка на ипотеку",
        dvizh_login: "Авторизация в ДВИЖ",
        realty_registration: "Онлайн регистрация",
        get_key: "Получение ключей"
    },
    email: {
        emailConfirmed: 'Ваш email подтвержден',
        goToCabinet: 'Перейти в кабинет',
        follow: 'Перейти'
    },
    realty: {
        floor: 'Этаж',
        apartment: 'Квартира',
        parking_space: 'Машиноместо',
        storage: 'Кладовая',
        non_residential: 'Нежилое',
        undefined: "Неопределенный",
        rooms: 'комнатная',
        room: 'Комнат',
        price: 'Стоимость',
        section: 'Секция',
        building: 'Корпус',
        finishing: 'Отделка',
        kitchen: 'Кухня',
        furniture: 'Мебель',
        address: 'Адрес',
        square_m2: 'Площадь в м²',
        square: 'Площадь',
        project: 'Проект',
        number: 'Номер',
    },
    coupon: coupon,
    users: users,
    forms: forms,
    settlement: settlement,
    booking: booking,
    mortgages: mortgages,
    realty_registration: realty_registration,
}
