export const realty_registration = {
    common: {
        statuses: {
            created: 'Created',
            in_progress: 'In progress',
            waiting_user_action: 'Waiting User Action',
            error: 'Ошибка',
            in_real_estate_register: 'In Real Estate Register',
            cancelled: 'Cancelled',
            registered: 'Registered',
        }
    },
    validations: {
        invalidCode: 'The code you entered is invalid. Please try again.',
    },
    deal_list: {
        title: 'Applications for registration',
        changed_date: 'Changed',
        application_date: 'Date of submission',
        planned_date: 'Target date',
        dvizh: 'Application',
        tooltips: {
            project: 'Go to project',
            changed_date: 'The last time the application data was updated',
            application_date: 'Target date',
            planned_date: 'The scheduled date is the date when the documents will be reviewed and the status of the application will be changed',
        },
    },
    application: {
        title: 'Application for online registration',
    },
};