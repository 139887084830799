export const forms = {
    validations: {
        required: "Поле должно быть заполнено.",
        minLength: "Не менее {min} символов(-а).",
        maxLength: "Не более {max} символов(-а).",
        numeric: "В поле должны быть только цифры.",
        email: "Неверный формат",
        invalidFormat: "Неверный формат",
        dateGreaterThanNow: "Дата не может быть больше текущей",
        birthDateAlreadyChanged: "Дата рождения уже менялась. Для повторного изменения обратитесь к администратору",
        invalidForms: 'На форме присутствуют ошибки',
        onlyLetters: 'В поле должны быть только буквы.',
        onlyRussianLetters: 'В поле должны быть только русские буквы.',
        maxFileSize: "Не удалось загрузить файл. Размер загружаемого файла не должен превышать 5 MB.",
        agreement: 'Ознакомьтесь с информацией',
        ageLessThan14: 'Регистрация доступна только с 14 лет',
        send_sms_error: 'Ошибка отправки смс',
    },
    shared: {
        phrases: {
            clipboard: 'Успешно сохранено в буфере',
            personalPolicy: 'Политика персональных данных',
            cookiePolicy: 'Политика обработки Cookie-файлов',
            personalData: 'Согласие на обработку персональных данных',
            feedback: 'Нашли ошибку? Напишите нам на',
            copy: 'Копировать',
            dataSaved: 'Данные успешно сохранены',
            leaveQuestion: 'Вы действительно хотите покинуть страницу? У Вас есть несохраненные изменения!',
            attention: 'Внимание',
            have_read: 'С информацией ознакомлен(а)',
            continue: 'Продолжить',
            personalData1: 'Согласие на',
            personalData2: 'обработку персональных данных',
            selectAll: 'Выбрать все',
            startDate: 'Дата начала',
            endDate: 'Дата окончания',
            save: 'Сохранить',
            setup: 'Настроить',
            confirm: 'Подтвердить',
            pay: 'Оплатить',
            more: 'Подробнее',
            sendCode: 'Введите код',
            sms: 'Для продолжения работы введите код из СМС, присланный на номер',
            dayOfWeek: {
                short: {
                    monday: 'Пн',
                    tuesday: 'Вт',
                    wednesday: 'Ср',
                    thursday: 'Чт',
                    friday: 'Пт',
                    saturday: 'Сб',
                    sunday: 'Вс'
                }
            },
            inMonth: 'в месяц',
            years: 'лет',
            million: 'млн'
        },
        back: 'Назад',
        well: 'Хорошо',
        dropdown: {
            select: 'Выбрать',
            not_selected: 'Не выбрано',
        },
        yesNo: {
            yes: 'Да',
            no: 'Нет'
        },
        date: {
            placeholder: "ДД.ММ.ГГГГ",
            label: "Дата",
        },
        time: {
            placeholder: "ЧЧ:ММ",
            label: "Время",
        },
        period: {
            week: "Неделя",
            day: "День",
            month: 'Месяц',
            months: 'Месяцы',
            months_genitive: 'Месяцев',
            years: 'Лет',
            month_short: 'Мес',
            days: "Дней",
            day_genitive: "Дня",
        },
        text: {
            show: "Показать",
            hide: "Скрыть"
        },
        email: {
            isVerified: "Адрес почты подтвержден",
            isNotVerified: "Адрес почты не подтвержден",
            emailVerifyMessageSent: "Мы отправили на указанную почту письмо, пожалуйста, подтвердите получение",
            verifyMessageResend: "Отправить еще раз"
        },
        grid: {
            loading: "Загрузка...",
            load: "Загрузить еще",
            outOf: "из",
            noDataTitle: 'Ничего не нашлось',
            noDataText: 'Упростите запрос и попробуйте снова'
        },
        notification:{
            success: 'Операция успешно выполнена',
            error: 'Ошибка',
            warning: 'Внимание',
            information: 'Доп. информация'
        },
        fileInput: {
            download: 'Скачать файл'
        },
        prepositions: {
            from: 'c',
            from_1: 'от',
            to: 'по',
            at: 'в',
            until: 'до',
            outOf: "из",
        },
        sex: {
            man: 'Мужчина',
            woman: 'Женщина'
        },
        dimensions: {
            meters: 'м',
            meters2: 'м²',
        },
        terms: {
            apartment: 'Квартира',
            address: 'Адрес',
            object: 'Объект',
            owner: 'Собственник',
        },
        all: 'Все',
        go_to: 'Перейти',
    },
    labels: {
        lastName:'Фамилия',
        firstName:'Имя',
        patronymicName: 'Отчество',
        birthDate:'Дата рождения',
        placeOfBirth:'Место рождения',
        email: 'Email',
        passportSeriesNumber: 'Серия и номер паспорта',
        passportDate: 'Дата выдачи',
        passportCode: 'Код подразделения',
        passportIssued: 'Кем выдан',
        registrationAddress: 'Адрес по месту прописки',
        residenceAddress: 'Адрес фактического проживания',
        phoneNumber: 'Номер телефона',
    },
    placeholders: {
        lastName: 'Введите фамилию',
        firstName: 'Введите имя',
        patronymicName: 'Введите отчество (если есть)',
        birthDate: 'Введите дату рождения',
        placeOfBirth: 'Введите место рождения',
        email: 'Введите email',
        passportSeries: 'Введите серию паспорта',
        passportNumber: 'Введите номер паспорта',
        passportDate: 'Введите дату выдачи',
        passportCode: 'Введите код подразделения',
        passportIssued: 'Введите название учреждения',
        registrationAddress: 'Введите адрес по месту прописки',
        residenceAddress: 'Введите адрес проживания',
    },
    tags: {
        address: "Адрес объекта недвижимости",
        realtyType: "Тип объекта недвижимости",
        number: "Номер БТИ объекта",
        fullName: "ФИО получателя",
        settlementLink: "Ссылка на сервис Заселение в ЛК",
        dearGender: "Персональное обращение к клиенту по полу"
    },
};